export const API = '/lambda'

export const MQ = {
  mobile: 'only screen and (max-width: 679px)',
  tablet: 'only screen and (min-width: 680px) and (max-width: 959px)',
  desktop: 'only screen and (min-width: 960px)',
  wide: 'only screen and (min-width: 1400px)',
  hd: 'only screen and (min-width: 1900px)',
}

export const MEMBER_DEFAULTS = {
  name: undefined,
  surname: undefined,
  rc: undefined,
  lecture: [],
  insurance: false,
  applySibling: false,
  sibling: undefined,
  isHelperChild: false,
  tshirt: false,
  tshirtSize: undefined,
} as const

export const MEMBER_DEFAULTS_DEV = {
  name: 'John',
  surname: 'Doe',
  rc: '121221/0000',
  lecture: [],
  insurance: false,
  applySibling: false,
  sibling: undefined,
  isHelperChild: false,
  tshirt: false,
  tshirtSize: undefined,
} as const

export const PARENT_DEFAULTS = {
  email: 'gsus0187@gmail.com',
  phone: '700123456',
  address: 'Klidná 12',
  city: 'Praha',
  agreement: true,
}

export const CITY_OPTIONS = [
  'Bernartice',
  'Blatná',
  'Cerhonice',
  'Čížová',
  'Dobev',
  'Dolní Novosedly',
  'Drhovle',
  'Heřmaň',
  'Kestřany',
  'Kluky',
  'Milevsko',
  'Mirotice',
  'Mirovice',
  'Olešná',
  'Písek',
  'Podolí I',
  'Praha',
  'Protivín',
  'Předotice',
  'Sedlice',
  'Strakonice',
  'Vodňany',
  'Vojníkov',
  'Vráž',
  'Záhoří',
]

export const CITY_PART_OPTIONS = [
  'Budějovické Předměstí',
  'Hradiště',
  'Nový Dvůr',
  'Pražské Předměstí',
  'Purkratice',
  'Semice',
  'Smrkovice',
  'Václavské Předměstí',
  'Vnitřní Město',
]

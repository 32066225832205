import { useEffect, useState, useMemo, useCallback } from 'react'
import { MQ } from '../lib/constants'
import { useMounted } from './useMounted'

export function useMedia<T> (queries: string[], values: T[], defaultValue: T) {
  if (typeof window === 'undefined') return defaultValue

  // Array containing a media query list for each query
  const mediaQueryLists = useMemo(() => queries.map(q => window.matchMedia(q)), [queries])
  const getValue = useCallback(() => {
    // Get index of first media query that matches
    const index = mediaQueryLists.findIndex(mql => mql.matches)
    return values[index] ?? defaultValue
  }, [mediaQueryLists, values, defaultValue])

  const [value, setValue] = useState(getValue)

  useEffect(() => {
    const handler = () => setValue(getValue())

    mediaQueryLists.forEach(mql => mql.addEventListener('change', handler))

    return () => mediaQueryLists.forEach(mql => mql.removeEventListener('change', handler))
  }, [getValue, setValue, mediaQueryLists])

  useEffect(() => {
    const handler = () => setValue(getValue())

    window.addEventListener('resize', handler)

    return () => window.removeEventListener('resize', handler)
  }, [getValue, setValue])

  return useMounted() ? value : defaultValue
}

export function useIsMobile (defaultValue?: boolean): boolean {
  const mounted = useMounted()
  const isMobile = useMedia<boolean>([MQ.mobile], [true], defaultValue ?? false)
  return (isMobile && mounted)
}

export function useIsTablet (defaultValue?: boolean): boolean {
  const mounted = useMounted()
  const isTablet = useMedia<boolean>([MQ.tablet], [true], defaultValue ?? false)
  return (isTablet && mounted)
}

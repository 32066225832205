import React, { createContext, PropsWithChildren, ReactNode, useCallback, useContext, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { AnimatePresence } from 'framer-motion'

import { useMounted, useWindow } from '../hooks'
import { EmptyObject } from '../types'

const noop = () => {} // eslint-disable-line @typescript-eslint/no-empty-function

type ShowModal = (modal: ReactNode) => () => void
type HideModal = () => void

const ModalContext = createContext<{
  showModal: ShowModal
  hideModal: HideModal
}>({
  showModal: () => noop,
  hideModal: noop,
})

export default ModalContext

export function useModalContext() {
  return useContext(ModalContext)
}

export function ModalProvider({ children }: PropsWithChildren<EmptyObject>) {
  const window = useWindow()
  const mounted = useMounted()
  const [modal, setModal] = useState<ReactNode | null>(null)

  const showModal: ShowModal = useCallback((modal: ReactNode) => {
    setModal(modal)
    return () => setModal(null)
  }, [setModal])

  const hideModal: HideModal = useCallback(() => void setModal(null), [setModal])

  return (
    <ModalContext.Provider value={{ showModal, hideModal }}>
      {children}
      {window && mounted && (
        createPortal((
          <AnimatePresence>
            {modal}
          </AnimatePresence>
        ), window.document.querySelector('#modal-root') ?? window.document.body)
      )}
    </ModalContext.Provider>
  )
}

export function Modal({ children }: PropsWithChildren<EmptyObject>) {
  const { showModal, hideModal } = useContext(ModalContext)

  const handleKeyup = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Escape') hideModal()
  }, [hideModal])

  useEffect(() => {
    if (!children) return

    window.document.addEventListener('keyup', handleKeyup)
    showModal(children)

    return () => {
      window.document.removeEventListener('keyup', handleKeyup)
      hideModal()
    }
  }, [children])

  return null
}

/* global window, document */

import React from 'react'

import { ModalProvider } from './src/contexts/ModalContext'

export const wrapRootElement = ({ element }) => (
  <ModalProvider>{element}</ModalProvider>
)

/*
const scrollTo = (id) => () => {
  try {
    const el = document.querySelector(id)
  } catch (err) {
    return false;
  }
  const scrollArea = document.querySelector('.main')
  if (el) {
    return scrollArea.scrollTo ?
      scrollArea.scrollTo({
        left: 0,
        top: el.offsetTop - 20,
        behavior: 'smooth'
      }) :
      scrollArea.scrollTop = el.offsetTop - 20
  } else {
    return false
  }
}

export const onRouteUpdate = ({ location: { hash } }) => {
  if (hash) {
    const scrollArea = document.querySelector('.main')
    scrollArea.scrollTop = 0
    window.setTimeout(scrollTo(hash), 750)
  }
}

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {
  if (location.hash) {
    return false
  } else {
    return true
  }
}
 */

import { useLayoutEffect } from 'react'

export function useLockBodyScroll(el = document.body) {
  useLayoutEffect(() => {
   // Get original body overflow
   const originalStyle = window.getComputedStyle(el).overflow
   // Prevent scrolling on mount
   el.style.overflow = 'hidden'
   // Re-enable scrolling when component unmounts
   return () => el.style.overflow = originalStyle
   }, []) // Empty array ensures effect is only run on mount and unmount
}

export default useLockBodyScroll

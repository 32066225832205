import { useState, useCallback, useEffect } from 'react'
import { Nullable } from '../types'

export type AsyncStatus = 'idle' | 'pending' | 'success' | 'error'

export function useAsync<T>(asyncFunction: () => Promise<T>, immediate = true) {
  const [status, setStatus] = useState<AsyncStatus>('idle')
  const [value, setValue] = useState<Nullable<T>>(null)
  const [error, setError] = useState<Nullable<Error>>(null)

  // The execute function wraps asyncFunction and
  // handles setting state for pending, value, and error.
  // useCallback ensures the below useEffect is not called
  // on every render, but only if asyncFunction changes.
  const execute = useCallback(() => {
    setStatus('pending')
    setValue(null)
    setError(null)

    return asyncFunction()
      .then(response => {
        setValue(response)
        setStatus('success')
      })
      .catch((error: Error) => {
        setError(error)
        setStatus('error')
      })
  }, [asyncFunction])

  // Call execute if we want to fire it right away.
  // Otherwise execute can be called later, such as
  // in an onClick handler.
  useEffect(() => {
    if (immediate) {
      const runningEffect = execute()
      return runningEffect.reject
    }
  }, [execute, immediate])

  return { execute, status, value, error }
}

export default useAsync

export function useAsyncEffect(effect, deps = []) {
  useEffect(() => {
    const runningEffect = effect()
    return runningEffect.reject
  }, deps)
}
